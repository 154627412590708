import navLogo from "@/assets/images/nav-logo.svg";
import {
  ArrowLeft,
  InstagramLogo,
  LinkedinLogo,
  XLogo,
  YoutubeLogo,
} from "@phosphor-icons/react/dist/ssr";
import Image from "next/image";
import Link from "next/link";
import LucidMascot from "@/assets/images/lucid-mascot.png";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/router";

export default function NotFound() {
  const router = useRouter();
  return (
    <div className='relative overflow-hidden'>
      <span className='absolute blur-[100px] opacity-30 top-0 left-0 rounded-full bg-gradient-to-tr from-red-500 to-blue-500 w-[400px] h-[400px]'></span>
      <span className='absolute blur-[100px] opacity-20 top-[350px] left-[300px] rounded-full bg-gradient-to-tr from-purple-500 to-pink-500 w-[400px] h-[400px]'></span>
      <span className='absolute blur-[100px] opacity-30 bottom-0 right-[100px] rounded-full bg-gradient-to-tr from-orange-500 to-red-500 w-[400px] h-[400px]'></span>
      <span className='absolute blur-[100px] opacity-30 bottom-[400px] right-[300px] rounded-full bg-gradient-to-tr from-yellow-500 to-purple-500 w-[400px] h-[400px]'></span>
      <div className='max-w-5xl px-4 mx-auto lg:flex'>
        <div className='relative hidden w-1/3 overflow-hidden border-r lg:block mr-14 border-white/5'>
          <div className='absolute top-1/2 -translate-y-1/2 right-0 translate-x-[160px] inline-block flex-shrink-0 relative h-56 lg:h-96 rotate-[-27deg] aspect-[199/320]'>
            <Image
              src={LucidMascot.src}
              alt=''
              layout={"fill"}
              objectFit={"cover"}
            />
          </div>
        </div>
        <div className='relative flex flex-col items-start justify-between flex-1 h-screen py-6 text-center lg:text-left'>
          <div className='inline-block relative w-auto h-10 2xl:h-14 aspect-[189/48]'>
            <Image
              src={navLogo.src}
              alt='Lucidity Insights'
              layout='fill'
              objectFit='cover'
            />
          </div>
          <div>
            <div className='flex justify-center mb-6 overflow-hidden border-b lg:hidden border-white/10'>
              <div className='lg:hidden inline-block flex-shrink-0 relative h-56 translate-y-14 aspect-[199/320]'>
                <Image
                  src={LucidMascot.src}
                  alt=''
                  layout={"fill"}
                  objectFit={"cover"}
                />
              </div>
            </div>
            <p className='text-3xl font-bold leading-tight tracking-tight lg:text-6xl'>
              Whoops!
            </p>
            <p className='max-w-xl mt-3 text-2xl font-bold lg:mt-5'>
              {`We couldn't find the page you were looking for.`}
            </p>
            <p className='max-w-xl mt-2 text-sm opacity-50'>
              {`It seems you’ve taken a wrong turn or stumbled upon a broken link. Don’t worry; we’re here to help you get back on track.`}
            </p>

            <Button onClick={() => router.back()} className='mt-6'>
              <ArrowLeft weight='bold' className='mr-2 text-lg' />
              Go to Previous Page
            </Button>

            {/* <div className='flex items-center justify-center gap-2 mt-6 text-2xl lg:justify-start text-background'>
              <Link
                href='https://www.linkedin.com/company/lucidity-insights/'
                target='_blank'
                className='flex items-center justify-center w-10 h-10 bg-white rounded-full'
              >
                <LinkedinLogo weight='fill' />
              </Link>
              <Link
                href='https://twitter.com/lucidityinsight'
                target='_blank'
                className='flex items-center justify-center w-10 h-10 bg-white rounded-full'
              >
                <XLogo weight='fill' />
              </Link>
              <Link
                href='https://www.instagram.com/lucidityinsights/'
                target='_blank'
                className='flex items-center justify-center w-10 h-10 bg-white rounded-full'
              >
                <InstagramLogo weight='fill' />
              </Link>
              <Link
                href='https://www.youtube.com/@lucidityinsights'
                target='_blank'
                className='flex items-center justify-center w-10 h-10 bg-white rounded-full'
              >
                <YoutubeLogo weight='fill' />
              </Link>
            </div>
            <p className='mt-6 text-sm opacity-50'>{`Don't go too far. We'll Be Right Back!`}</p> */}
          </div>
          <p className='text-xs text-center opacity-40 lg:text-left'>
            © Copyright {new Date().getFullYear()} Lucidity Insights.
          </p>
        </div>
      </div>
    </div>
  );
}
